import React, { useEffect } from 'react';

function Login(props) {
  useEffect(() => {
    window.location.replace('https://ehsadmin.nbcuni.com/admin/content');
  }, []);

  return (
    <>
      <main>
        <section className="main-inner-section">
          <div className="container banner-container">
            <div className="page-not-found-wrap">
              <h1 className="mb-3">Redirecting to Admin</h1>
              <h5>Please wait for a second.</h5>
            </div>
          </div>
        </section>
      </main>

    </>
  )
}

export default Login
